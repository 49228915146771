import React from "react"
import { Container, Row, Col, Badge } from "reactstrap"

import Layout from "src/components/Layout"
import Seo from "src/components/Seo"
import Heading from "src/components/index/Heading"
import ViewMoreFeatures from "src/components/features/ViewMoreFeatures"
import TestimonialCard from "src/components/TestimonialCard"

import chrisAvatar from "src/assets/img/testimonials/chris-medium600.jpg"

import viewInvoices from "src/assets/img/screenshots/revenue-enhancement-view-invoices.png"
import reminderEmail from "src/assets/img/screenshots/revenue-enhancement-reminder-email.png"
import automaticCancellationEmail from "src/assets/img/screenshots/revenue-enhancement-automatic-cancellation-email.png"

const Feature = () => {
  return (
    <Layout>
      <Seo
        title="Carebit: Features | Revenue enhancement"
        pathname="/features/revenue-enhancement"
        description="Carebit’s revenue enhancement tools maximise your income and protect against bad debts."
      />
      <div className="u-centerFlex u-textCenter Hero">
        <Container>
          <Row>
            <Col
              lg={{ size: 6, offset: 3 }}
              md={{ size: 10, offset: 1 }}
              style={{ zIndex: 2, padding: "120px 40px 200px", color: "white" }}
            >
              <Badge color="info" className="u-marginBottom20">
                Features
              </Badge>
              <h1>Revenue enhancement</h1>
              <p className="u-whiteTranslucent">
                More often than not, doctors become distracted from the
                financial side of their business. Bad debts are allowed to build
                up and without a rigorous system in place to identify patients
                who haven’t paid, significant sums can be lost.
              </p>
              <p className="u-whiteTranslucent">
                Carebit includes several features as standard to maximise your
                revenue and to make the most of your valuable time.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container>
          <Row>
            <Col md={{ size: 10, offset: 1 }}>
              <section>
                <Row className="u-centerFlex">
                  <Col lg={6} md={12} className="SplitContentRow-text">
                    <Row>
                      <Heading title="At a glance">
                        Easily identify outstanding invoices
                      </Heading>
                    </Row>
                    <p className="u-midGrey">
                      Carebit makes it easy to spot invoices that haven’t been
                      paid yet using intuitively coloured labels, so staff can
                      follow up manually with the patient if need be.
                    </p>
                  </Col>
                  <Col
                    lg={{ size: 5, offset: 1 }}
                    md={{ size: 12 }}
                    className="SplitContentRow-graphic"
                  >
                    <img
                      alt="Carebit - view invoices"
                      src={viewInvoices}
                      className="u-outline"
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>
              </section>

              <section>
                <Row className="u-centerFlex">
                  <Col lg={5} md={12} className="SplitContentRow-graphic">
                    <img
                      alt="Carebit - payment reminder email"
                      src={reminderEmail}
                      className="u-outline"
                      style={{ width: "100%" }}
                    />
                  </Col>
                  <Col
                    lg={{ size: 6, offset: 1 }}
                    md={{ size: 12 }}
                    className="SplitContentRow-text"
                  >
                    <Row>
                      <Heading title="Automatic reminders">
                        Payment reminder emails ensure payment is made on time
                      </Heading>
                    </Row>
                    <p className="u-midGrey">
                      With some followup appointments booked many months into
                      the future, it’s only natural that patients need reminding
                      to pay near to the time of the appointment. Carebit’s
                      customisable payment emails politely remind the patient to
                      pay online before the appointment, saving your practice
                      time in chasing them up after the appointment. A receipt
                      is then automatically emailed to them.
                    </p>
                  </Col>
                </Row>
              </section>

              <section>
                <Row className="u-centerFlex">
                  <Col
                    lg={{ size: 6 }}
                    md={{ size: 12 }}
                    className="SplitContentRow-text"
                  >
                    <Row>
                      <Heading title="Automatic cancellation">
                        Insist on payment before the appointment
                      </Heading>
                    </Row>
                    <p className="u-midGrey">
                      Many healthcare systems (such as the French system) insist
                      on payment before the appointment to ensure the best
                      collection rates. In this spirit, Carebit can be
                      configured to automatically cancel the appointment on the
                      day if payment is not received in full, sending an email
                      to both the patient and the doctor.
                    </p>
                  </Col>
                  <Col
                    lg={{ size: 5, offset: 1 }}
                    md={{ size: 12 }}
                    className="SplitContentRow-graphic"
                  >
                    <img
                      alt="Carebit - automatic cancellation email"
                      src={automaticCancellationEmail}
                      className="u-outline"
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>
              </section>
            </Col>
          </Row>
        </Container>

        <div className="Index-gradient">
          <div className="ColouredDivider Index-dividerBlueGreen">
            <Container>
              <section>
                <TestimonialCard
                  author="Professor Christopher Eden"
                  authorSubtext="Leading robotic radical prostatectomy expert, Santis"
                  avatarSrc={chrisAvatar}
                >
                  <p>
                    “The revenue enhancement feature ensures that you only do
                    work that you are definitely going to be paid for, because
                    you already have been paid by the time you see the patient!
                    This not only frees up your time but also reduces the need
                    for fee collection and its attached costs. Why would anyone
                    want to work a different way?”
                  </p>
                </TestimonialCard>
              </section>
            </Container>
          </div>
        </div>

        <ViewMoreFeatures />
      </div>
    </Layout>
  )
}

export default Feature
